import React from "react";

const logo = () => {
	return (
		<div className="logo-parent">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 297.39 111.02"
				className="logo"
			>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path d="M35.52,72.23A25.5,25.5,0,0,0,61,46.76V27.7a5.89,5.89,0,0,0-11.78,0V46.76a13.7,13.7,0,1,1-27.4,0V27.7A5.89,5.89,0,1,0,10,27.7V46.76A25.5,25.5,0,0,0,35.52,72.23Z" />
						<path d="M82,0a7.61,7.61,0,1,0,7.6,7.6A7.62,7.62,0,0,0,82,0Z" />
						<path d="M82,21.81a5.9,5.9,0,0,0-5.89,5.89V45.84A40.61,40.61,0,0,1,9.63,77.12,5.89,5.89,0,1,0,2.14,86.2,52.38,52.38,0,0,0,87.87,45.84V27.7A5.9,5.9,0,0,0,82,21.81Z" />
						<path d="M112.15,53.13A15.63,15.63,0,0,0,119.69,55a15.35,15.35,0,0,0,5.72-1.07,14.47,14.47,0,0,0,2.65-1.38,4.28,4.28,0,0,0,.72.94,4.44,4.44,0,0,0,6.18,0,4.23,4.23,0,0,0,1.19-3V25.85a4.36,4.36,0,0,0-7.36-3.11,4.15,4.15,0,0,0-1.25,3.11V41.36a4.75,4.75,0,0,1-.82,2.74,5.9,5.9,0,0,1-2.39,2,7.9,7.9,0,0,1-3.54.78A7.32,7.32,0,0,1,115.47,45c-1.28-1.22-1.93-3.12-1.93-5.65V25.85a4.31,4.31,0,1,0-8.61,0V39.38a17.4,17.4,0,0,0,1.89,8.33A13.14,13.14,0,0,0,112.15,53.13Z" />
						<path d="M149,53.67a22.78,22.78,0,0,0,7.53,1.24,17.28,17.28,0,0,0,7-1.31,10.73,10.73,0,0,0,4.68-3.79,9.62,9.62,0,0,0,1.63-5.42,8.45,8.45,0,0,0-3-6.7c-1.88-1.61-4.88-2.82-9.17-3.68a25.7,25.7,0,0,1-4.19-1.16A3.48,3.48,0,0,1,152,32a1.55,1.55,0,0,1-.26-.95c0-.22,0-.63.82-1.06a7.55,7.55,0,0,1,3.46-.68,10.49,10.49,0,0,1,3.26.44,6.93,6.93,0,0,1,2.39,1.48,5.23,5.23,0,0,0,3.59,1.43,4,4,0,0,0,2.49-.78A3.63,3.63,0,0,0,169.21,29a4.25,4.25,0,0,0-1.05-2.71,12.36,12.36,0,0,0-5.08-3.63,18.38,18.38,0,0,0-7-1.27,17.61,17.61,0,0,0-6,1,11.19,11.19,0,0,0-4.81,3.24,8.27,8.27,0,0,0-2,5.54c0,5.24,4,8.65,11.79,10.16A15.63,15.63,0,0,1,160.34,43c.92.61,1,1.17,1,1.59a1.67,1.67,0,0,1-1,1.52,8.14,8.14,0,0,1-4.1.83,10.13,10.13,0,0,1-3.89-.75,7,7,0,0,1-2.63-1.67,4.22,4.22,0,0,0-6-.36,4.05,4.05,0,0,0-1.7,3.34,3.86,3.86,0,0,0,1.15,2.77A15.11,15.11,0,0,0,149,53.67Z" />
						<path d="M181,52.7a14.89,14.89,0,0,0,8,2.21,15.14,15.14,0,0,0,6.59-1.47,16,16,0,0,0,2.72-1.67,4,4,0,0,0,1,1.73,4.31,4.31,0,0,0,7.38-3.06V38.17a16.84,16.84,0,0,0-2.21-8.53,16.33,16.33,0,0,0-6-6.05,16.55,16.55,0,0,0-8.43-2.21,16.73,16.73,0,0,0-8.47,2.21,16,16,0,0,0-6,6.06,17.09,17.09,0,0,0-2.18,8.52,17.91,17.91,0,0,0,2,8.48A15.36,15.36,0,0,0,181,52.7Zm.77-14.53a9.47,9.47,0,0,1,1.11-4.56,8.29,8.29,0,0,1,3-3.15,8.37,8.37,0,0,1,8.4,0,8,8,0,0,1,2.95,3.14,9.61,9.61,0,0,1,1.09,4.57,9.43,9.43,0,0,1-1.09,4.54A7.95,7.95,0,0,1,190.05,47a7.89,7.89,0,0,1-4.2-1.13,8.3,8.3,0,0,1-3-3.13A9.29,9.29,0,0,1,181.76,38.17Z" />
						<path d="M256,24.94h0a11.62,11.62,0,0,0-8.83-3.6A14.35,14.35,0,0,0,241,22.62a13.8,13.8,0,0,0-3.41,2.27,11,11,0,0,0-3.06-2.27,12.46,12.46,0,0,0-5.71-1.29,12.91,12.91,0,0,0-4.85.9,12.29,12.29,0,0,0-2.16,1.13,4,4,0,0,0-.48-.54,4.19,4.19,0,0,0-3-1.16,4.25,4.25,0,0,0-3.05,1.19A4.15,4.15,0,0,0,214,26V50.44a4.36,4.36,0,0,0,7.42,3.05,4.25,4.25,0,0,0,1.19-3.05V34.93a5.63,5.63,0,0,1,1.44-4,4.73,4.73,0,0,1,3.66-1.5,4.46,4.46,0,0,1,3.4,1.27,5.63,5.63,0,0,1,1.26,4.05V50.44a4.21,4.21,0,0,0,1.23,3.06,4.47,4.47,0,0,0,6.19,0A4.25,4.25,0,0,0,241,50.44V34.93a5.63,5.63,0,0,1,1.44-4,4.66,4.66,0,0,1,3.6-1.5,4.48,4.48,0,0,1,3.41,1.27,5.68,5.68,0,0,1,1.25,4.05l0,33.13a15.67,15.67,0,0,0-2.52-1.53,15.16,15.16,0,0,0-6.59-1.46,15,15,0,0,0-8,2.2,15.35,15.35,0,0,0-5.6,6,17.93,17.93,0,0,0-2,8.48,17.06,17.06,0,0,0,2.17,8.52,16,16,0,0,0,6,6.06,16.74,16.74,0,0,0,8.48,2.21,16.33,16.33,0,0,0,14.42-8.26,16.94,16.94,0,0,0,2.2-8.53l0-46.87C259.33,30.59,258.21,27.3,256,24.94Zm-5,56.64a9.55,9.55,0,0,1-1.08,4.57,7.92,7.92,0,0,1-7.15,4.27,7.89,7.89,0,0,1-4.2-1.13,8.22,8.22,0,0,1-3-3.15,9.9,9.9,0,0,1,0-9.09,8.38,8.38,0,0,1,3-3.12,7.89,7.89,0,0,1,4.2-1.13A8,8,0,0,1,249.89,77,9.43,9.43,0,0,1,251,81.58Z" />
						<path d="M295.19,29.64h0a16.33,16.33,0,0,0-14.42-8.26,16.74,16.74,0,0,0-8.48,2.21,16,16,0,0,0-6,6.06,17.08,17.08,0,0,0-2.17,8.52,18,18,0,0,0,2,8.48,15.43,15.43,0,0,0,5.6,6.05,14.92,14.92,0,0,0,8,2.21,15.17,15.17,0,0,0,6.59-1.47A16.3,16.3,0,0,0,289,51.77a4.31,4.31,0,0,0,8.42-1.33V38.17A16.94,16.94,0,0,0,295.19,29.64ZM289,38.17a9.43,9.43,0,0,1-1.08,4.54h0A7.95,7.95,0,0,1,280.77,47a7.87,7.87,0,0,1-4.2-1.13,8.25,8.25,0,0,1-3-3.13,9.9,9.9,0,0,1,0-9.09,8.21,8.21,0,0,1,3-3.15,7.89,7.89,0,0,1,4.2-1.13,7.89,7.89,0,0,1,7.14,4.27A9.5,9.5,0,0,1,289,38.17Z" />
						<path d="M116.48,65.07a4.22,4.22,0,0,0-3,1.19,4.12,4.12,0,0,0-1.26,3.11V96.54a5.67,5.67,0,0,1-1.64,4.24,5.8,5.8,0,0,1-4.28,1.63,4.06,4.06,0,0,0-3.06,1.26,4.66,4.66,0,0,1-.45.4,1.74,1.74,0,0,0-.74,1.65c0,.91.29,3.11,1.19,4a4.09,4.09,0,0,0,3.06,1.25,15.25,15.25,0,0,0,7.49-1.84,13.14,13.14,0,0,0,5.2-5.2,15.14,15.14,0,0,0,1.84-7.44V69.37a4.24,4.24,0,0,0-1.21-3.1A4.2,4.2,0,0,0,116.48,65.07Z" />
						<path d="M150.6,67.06h0a16.48,16.48,0,0,0-8.43-2.21,16.77,16.77,0,0,0-8.47,2.2,16,16,0,0,0-6,6.06,17.12,17.12,0,0,0-2.17,8.53,17.93,17.93,0,0,0,2,8.48,15.35,15.35,0,0,0,5.6,6,15.5,15.5,0,0,0,14.55.73,14.73,14.73,0,0,0,2.71-1.67,4.12,4.12,0,0,0,1,1.74,4.3,4.3,0,0,0,7.37-3.07V81.64a16.88,16.88,0,0,0-2.2-8.53A16.33,16.33,0,0,0,150.6,67.06Zm-.19,14.58a9.37,9.37,0,0,1-1.09,4.53h0A8.21,8.21,0,0,1,138,89.29a8.23,8.23,0,0,1-3-3.13,9.15,9.15,0,0,1-1.11-4.52A9.33,9.33,0,0,1,135,77.08a8.22,8.22,0,0,1,3-3.15,7.89,7.89,0,0,1,4.2-1.13,7.93,7.93,0,0,1,7.15,4.26A9.62,9.62,0,0,1,150.41,81.64Z" />
						<path d="M190.51,67h0a4.09,4.09,0,0,0-1.68-1.42,4.39,4.39,0,0,0-4,.12,3.85,3.85,0,0,0-1.59,1.7l-8.1,17.67L166.9,67.38a3.89,3.89,0,0,0-1.52-1.64,4,4,0,0,0-3.85-.13,3.92,3.92,0,0,0-2.37,3.6,3.75,3.75,0,0,0,.36,1.63L171.2,95.45a5,5,0,0,0,1.59,2,4.26,4.26,0,0,0,6.27-2l11.65-24.55a3.84,3.84,0,0,0,.43-1.72A3.77,3.77,0,0,0,190.51,67Z" />
						<path d="M216.45,66.93h0a15.45,15.45,0,0,0-8.1-2.08A16.61,16.61,0,0,0,199.93,67a15.29,15.29,0,0,0-5.85,6A17.8,17.8,0,0,0,192,81.64a17,17,0,0,0,2.21,8.61,15.84,15.84,0,0,0,6.16,6A18.35,18.35,0,0,0,215,97.36,17,17,0,0,0,220,94.72a3.5,3.5,0,0,0,1.47-2.85A4.09,4.09,0,0,0,217.37,88a4.27,4.27,0,0,0-2.72.91,9,9,0,0,1-2.52,1.26,9.47,9.47,0,0,1-2.95.52,9.29,9.29,0,0,1-6.12-2,8.06,8.06,0,0,1-2.53-3.56h19A3.94,3.94,0,0,0,222.37,84a3.8,3.8,0,0,0,1.18-2.81,18.75,18.75,0,0,0-1.81-8.36A13.88,13.88,0,0,0,216.45,66.93ZM200.62,77.66a7.76,7.76,0,0,1,2.21-3.26,8,8,0,0,1,5.52-1.82,6.59,6.59,0,0,1,4.84,1.77,7.62,7.62,0,0,1,1.95,3.31Z" />
					</g>
				</g>
			</svg>
		</div>
	);
};

export default logo;
